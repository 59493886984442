import React from "react";
import PropTypes from "prop-types";

const formatPhoneNumber = (phone) => {
  if (phone.includes("+33 0")) {
    return phone.replace("+33 0", "+33");
  }

  if (phone.includes("+33 ")) {
    return phone.replace("+33 ", "+33");
  }

  return phone;
};

const PhoneLink = ({ phone }) => (
  <a
    className="phone-link"
    href={"tel:" + formatPhoneNumber(phone)}
    rel="noreferrer"
  >
    {formatPhoneNumber(phone)}
  </a>
);

PhoneLink.propTypes = {
  phone: PropTypes.string.isRequired,
};

const ClientInformations = ({ client, magasin }) => {
  const clientMode = client && typeof client === "object";
  const data = clientMode ? client : magasin;

  return (
    <div className="client-informations">
      <div className="client-informations__left-container">
        <div className="client-informations__name">{`${data.identity.lastname} ${data.identity.firstname}`}</div>
        <div className="client-address">
          {clientMode ? (
            <div className="client-address__floor">
              Etage : {data.address.floor}
            </div>
          ) : null}
          <div className="client-address__address">
            {(data.address.address || "").split("\n").map((address_line, n) => (
              <div key={"aline-" + n}>{address_line}</div>
            ))}
          </div>
          <div className="client-address__address-addition">
            {(data.address.address_addition || "")
              .split("\n")
              .map((address_add_line, n) => (
                <div key={"adress-add-line-" + n}>{address_add_line}</div>
              ))}
          </div>
          <div className="client-address__postal-code-city">
            {`${data.address.postal_code.postal_code} ${data.address.postal_code.city}`}
          </div>
        </div>
        <div className="client-contact__email">
          {`Email : ${client.identity.email || "N.C."}`}
        </div>
      </div>
      <div className="client-informations__right-container">
        {clientMode ? (
          <div className="client-contact">
            <div className="client-contact__phone">
              <span>Tél. :</span>
              {client.identity.phone ? (
                <PhoneLink phone={client.identity.phone} />
              ) : null}

              <span>Port. :</span>
              {client.identity.phone_secondary ? (
                <PhoneLink phone={client.identity.phone_secondary} />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ClientInformations.propTypes = {
  client: PropTypes.shape({
    identity: PropTypes.shape({
      lastname: PropTypes.string.isRequired,
      phone_secondary: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    address: PropTypes.shape({
      address: PropTypes.string.isRequired,
      floor: PropTypes.number.isRequired,
      postal_code: PropTypes.shape({
        postal_code: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
  magasin: PropTypes.shape({
    identity: PropTypes.shape({
      lastname: PropTypes.string.isRequired,
    }).isRequired,
    address: PropTypes.shape({
      address: PropTypes.string.isRequired,
      floor: PropTypes.number.isRequired,
      postal_code: PropTypes.shape({
        postal_code: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
};

export default ClientInformations;
