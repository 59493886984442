import React from "react";
import PropTypes from "prop-types";
import PickingArticle from "../PickingArticle";

const PickingTable = ({
  articles,
  handleScan,
  handleTakePhoto,
  handleDeletePhoto,
  resetPickupStatus,
  handleSuccessfulPickup,
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  canTakePhoto,
}) => {
  return (
    <div className="picking-table">
      <div className="picking-table__header">
        <div>Article</div>
        <div>EAN</div>
        <div></div>
        <div>Actions</div>
      </div>
      <div className="picking-table__content">
        {articles.map((article) => {
          return article.metadata.map((metadata, index) => (
            <PickingArticle
              key={`${article.id}_${index}`}
              article={article}
              metadata={metadata}
              handleScan={() => handleScan(metadata.path, article.ean)}
              handleTakePhoto={() => handleTakePhoto(metadata.path)}
              handleDeletePhoto={(updatedPhotos, photoBelongsToAnIncident) =>
                handleDeletePhoto(
                  metadata.path,
                  updatedPhotos,
                  photoBelongsToAnIncident
                )
              }
              resetPickupStatus={(resetIncident) =>
                resetPickupStatus(metadata.path, resetIncident)
              }
              handleSuccessfulPickup={() =>
                handleSuccessfulPickup(metadata.path)
              }
              updatePickupIncidentReason={(incidentReason, pickupSuccessful) =>
                updatePickupIncidentReason(
                  metadata.path,
                  incidentReason,
                  pickupSuccessful
                )
              }
              handleDamagedPackagingOrProductPhoto={() =>
                handleDamagedPackagingOrProductPhoto(metadata.path)
              }
              canTakePhoto={canTakePhoto}
            />
          ));
        })}
      </div>
    </div>
  );
};

PickingTable.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ean: PropTypes.string.ean,
    }).isRequired
  ).isRequired,
  handleScan: PropTypes.func.isRequired,
  handleTakePhoto: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  updatePickupIncidentReason: PropTypes.func.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  canTakePhoto: PropTypes.bool.isRequired,
};

export default PickingTable;
